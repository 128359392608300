<template>
<div class="product" >
 <Header url="product"/>
 <Head2 head2='1'  catechild="1" />


    <div class="pbox">
          <div class="w12 clearfix">
                <div class="item2 ">
                            <div class="item2title">看看怎么使用门店系统和工厂系统</div>
                            <div class="item2title2">How to use store system and factory system</div>
                            <div class="linex">——————</div>
                            <div class="item2img2box clearfix">
                                <div class="itemimg">
                                        <img  src="../../assets/img/proimg1.jpg" alt="">
                                        <div>门店系统</div>
                                </div>
                                <div class="itemimg">
                                        <img  src="../../assets/img/proimg2.jpg" alt="">
                                        <div>工厂系统</div>
                                </div>
                            </div>
                </div>
                <div class="item2">
                        <img class="itemsingleimg" src="../../assets/img/proimg3.jpg" alt="">
                </div>
          </div>
         <div class="box1bg"></div>

   </div>


   
    <div class="pbox">
          <div class="w12 clearfix">
              <div class="clearfix factable dod" v-for="(item,index) in list" :key="index">
                    <div class="item2 lrauto">
                            <img class="itemsingleimg2" :src="item.img" alt="">
                    </div>
                    <div class="item2 box2ping">
                                <div class="item2title ">{{item.title}}</div>
                                <div class="item2title2">{{item.titlezm}}</div>
                                <div class="item2desc">{{item.desc}}</div>
                    </div>
              </div>

                 <div class="linex2"> </div>
             
          </div>

   </div>


     <div class="pbox wash">
          <div class="w12 clearfix">
               <img class="washimg fl" src="../../assets/img/proimg7.jpg" alt="">
               <img class="washimg fr" src="../../assets/img/proimg8.jpg" alt="">
          </div>

     </div>

        <div class="pbox">
          <div class="w12 paybox">
               <div class="sintitle">多种组合 灵活收款</div>
               <div class="sintitle2">Flexible collection with multiple combinations</div>
               <div class="sindesc">多种组合支付方式叠加，可对优惠的组合方式进行设置。支持主扫和被扫</div>
               <div class="plinex2">—————</div>
                  <div class="payinfo clearfix">
                        <div class="payitem">
                             <div class="payimg"> <img class="payicon1" src="../../assets/img/payicon1.png" alt=""></div>
                             <div>微信支付</div>
                        </div>

                          <div class="payitem">
                             <div class="payimg"> <img class="payicon2" src="../../assets/img/payicon2.png" alt=""></div>
                             <div>支付宝支付</div>
                        </div>

                    

                          <div class="payitem">
                             <div class="payimg"> <img class="payicon4" src="../../assets/img/payicon4.png" alt=""></div>
                             <div>现金+微信</div>
                        </div>

                          <div class="payitem">
                             <div class="payimg"> <img class="payicon5" src="../../assets/img/payicon5.png" alt=""></div>
                             <div>现金+支付宝</div>
                        </div>

                             <div class="payitem">
                             <div class="payimg"> <img class="payicon3" src="../../assets/img/payicon3.png" alt=""></div>
                             <div>现金</div>
                        </div>
                  </div>

          </div>

     </div>



  <Footer />
  </div>
</template>

<script>
import '../../assets/css/index.css'
import './index.css'

import Header from '../../components/header'
import Head2 from '../../components/head2'

import Footer from '../../components/footer'
import {getgongneng} from '../../request/api'

export default {
    name: 'Product',
    components: {
        Header,
        Head2,
        Footer,
    },
    data() {
      return {
           list:[],
      }
    },
    mounted(){
        this.getData();
    },
    methods: {
        getData(){
                let that = this;
                console.log(1);
                getgongneng().then(res=>{
                      that.list=res.data
                })
        }
    }


}
</script>

